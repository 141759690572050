<template>
	<div>
		<header class="a-header isStatic">
			<div class="app-container app-container-fluid a-header__container">
				<div class="row align-items-center">
					<div class="col-auto">
						<div class="a-header__logo" >
							<router-link
								:to="$i18nRoute({ name: 'index' })"
								class="a-header__logo--link"
								v-html="require('!html-loader!@/assets/logo-color.svg')"
							/>
						</div>
					</div>
					<div class="col">
						<p class='a-share-trip-date'>{{order.dateFrom ? $moment(order.dateFrom).format("MMM DD") : ""}} {{order.dateTo ? `- ${$moment(order.dateTo).format("MMM DD")}` : ""}}</p>
					</div>
				</div>
			</div>
		</header>

		<div class='a-share-trip'>
			<div class="app-container a-share-trip-container">
				<div class="a-share-trip-header">
					<p class='a-share-trip-header-title'>{{order.name ? order.name : $t("account.trips.not_specified")}}</p>
					<p class='a-share-trip-header-text'>{{$t('account.trips.prices_are_for_reference_only')}} <span class='a-share-trip-header-date'>{{$moment(order.updatedAt).format("DD/MM/YYYY HH:MM")}}</span></p>
				</div>

				<a-timeline :order='order' result v-if='order.items && order.items.length' />

				<div class='a-share-trip-total' v-if='order.price'>
					<div class="a-share-trip-total-divider"></div>
					<div class='a-share-trip-total-wrap'>
						<p class='a-share-trip-total-text'>{{$t('account.trips.total')}}:</p>
						<p class='a-share-trip-total-title'>{{$helpers.amount(order.price.total, order.price.currency)}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ATimeline from '@/views/account/itinerary/timeline';
	import { getShareTrip } from '@/services/request';

	export default {
		data: () => ({
			order: {},
			name_tour: '',
			dialog: false
		}),
		components: {
			ATimeline
		},
		created() {
			this.getShareTrip();
		},
		methods: {
			getShareTrip() {
				getShareTrip(this.$route.params.orderId).then(res => {
					this.order = res.data;
					this.name_tour = res.data.name;
					this.changeTitle();
				})
			},
			changeTitle() {
				document.title = (this.name_tour && this.name_tour.length ? this.name_tour : this.$t("account.itinerary.content.name_tour")) + ' - ' + process.env.VUE_APP_TITLE;
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-share-trip {
		padding-top: 25px;
		padding-bottom: 100px;
		@include minw( $grid-breakpoints-xs ) {
			padding-top: 70px;
		}
		&-date {
			font-size: 16px;
			font-weight: 400;
			color: rgba($black, .5);
		}
		&-container {
			max-width: 875px;
		}
		&-header {
			text-align: center;
			&-title {
				font-size: 15px;
				font-weight: 400;
				line-height: 1.5;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 28px;
					font-weight: 700;
				}
			}
			&-text {
				font-size: 8px;
				font-weight: 400;
				line-height: 1.5;
				color: rgba($black, .5);
				@include minw( $grid-breakpoints-xs ) {
					font-size: 12px;
				}
			}
			&-date {
				font-size: 10px;
				font-weight: 400;
				line-height: 1.5;
				color: rgba($black, .8);
				@include minw( $grid-breakpoints-xs ) {
					font-size: 13px;
				}
			}
		}
		&-total {
			&-divider {
				margin: 20px 0 10px;
				border-top: 1px solid rgba($black, .1);
				display: block;
				@include minw( $grid-breakpoints-xs ) {
					margin: 30px 0 30px 40px;
				}
			}
			&-wrap {
				display: flex;
				align-items: baseline;
				justify-content: flex-end;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					justify-content: space-between;
				}
			}
			&-title {
				font-size: 16px;
				font-weight: 700;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 22px;
				}
			}
			&-text {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
				@include minw( $grid-breakpoints-xs ) {
					font-size: 13px;
					margin-left: 5px;
					margin-right: 5px;
				}
			}
		}
	}
</style>
